'use client';

import useHideIntercomChat from '~/hooks/useHideIntercomChat';

/**
 * Hides the Intercom chat on the page
 */
const HideIntercomChat = () => {
  useHideIntercomChat(true);

  return null; // No need to render anything for this component
};

export default HideIntercomChat;
