'use client';

import { GoogleTagManager } from '@next/third-parties/google';

/**
 * Renders Google Tag Manager component with GTM ID
 * @returns
 */
const GoogleTagManagerWrapper = () => {
  return (
    <GoogleTagManager
      gtmId={process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''}
    />
  );
};

export default GoogleTagManagerWrapper;
